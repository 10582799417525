import Strap from "@components/Strap"
import React, { ReactElement } from "react"
const styles = require("./index.module.css")

function AboutUsContainer(): ReactElement {
  const process = [
    {
      imageURL: require("@images/search.svg"),
      title: "Search",
      subTitle:
        "Use the search bar to find a ride best suiting your convinience and timing.",
    },
    {
      imageURL: require("@images/book.svg"),
      title: "Book",
      subTitle: "Fill up a simple form and book your ride with us instantly.",
    },
    {
      imageURL: require("@images/pickup.svg"),
      title: "Ride",
      subTitle: "Grab keys of your ride on scheduled date and time.",
    },
  ]
  return (
    <div>
      <div className="max-w-1366 mx-auto pt-16 px-8 md:px-0">
        <div className="flex items-center flex-wrap flex-row-reverse md:flex-row">
          <div className="w-full md:w-1/2">
            <div>
              <h3 className="text-2xl font-bold">About Us</h3>
            </div>
            <div>
              <h5 className="py-4 text-justify">
                At Rent-O-Ride, we specialise in car renting, helping to find
                customers the best deals available based on their specific
                needs. We have a wealth of knowledge and experience in car
                renting, so we can provide advice on which ride is best for you
                . Rent-O-Ride is a growing company and we have successful
                partnerships with some of the best vehicles inventory in the UK.
                This means that we have a broad range of cars to choose from,
                which strengthens our ability to find the best deals for you.
                With a passion for cars and motorsports, and a range of
                experience in vehicle renting, our company Director Ronnie Evans
                has written articles that provide a personal and honest insight
                into vehicle renting. As well as Ronnie, our representatives at
                Rent-O-Ride are on hand to provide expert advice and help when
                it comes to renting a new vehicle, so contact us today, to see
                how we can help. You can also email us on info@rent-o-ride.com .
                Follow us on Facebook, Instagram and Twitter to keep up-to-date
                with our latest deals and advice.
              </h5>
            </div>
          </div>
          <div className="w-full md:w-1/2 p-16">
            <img src={require("@images/about-us-sec-1.jpeg")} />
          </div>
        </div>
      </div>
      <div>
        <div className=" bg-gray-200 w-full my-16">
          <Strap
            items={[
              {
                imageURL: require("@images/check_icon.svg"),
                text: "Lowest time to possession",
              },
              {
                imageURL: require("@images/check_icon.svg"),
                text: "Fastest verifications",
              },
              {
                imageURL: require("@images/check_icon.svg"),
                text: "Ride now, Pay later",
              },
            ]}
            title="Why choose us?"
          />
        </div>
      </div>
      <div>
        <div className="max-w-1366 mx-auto ">
          <div className="hidden md:block text-center mb-16 mt-16 md:pl-8">
            <h3 className="text-3xl">Easy Process</h3>
          </div>
          <div className="flex flex-wrap mb-8 ">
            {process.map((p: any) => (
              <>
                <div className="w-full md:w-1/3 px-8 flex-col flex-wrap justify-center">
                  <div className=" flex justify-center items-center">
                    <img className="w-64 h-64" src={p.imageURL} />
                  </div>
                  <div className="my-8 flex text-center flex-col justify-center items-center">
                    <h3 className="text-2xl mb-2">{p.title}</h3>
                    <h5 className="text-sm">{p.subTitle}</h5>
                  </div>
                </div>
              </>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default AboutUsContainer
